import React from 'react';
import './style.scss';
import Wrapper from '../Wrapper/Wrapper';
import SEO from '../../components/seo';
import { useStaticQuery, graphql } from "gatsby";
import Fuse from 'fuse.js'; 
import Iff from '../../components/Iff';
import PageSummary from '../../components/SoftPagination/PageSummary';
import SoftPagination from '../../components/SoftPagination/SoftPagination';

export default ({pageContext}) => {
    
    // const function getParams(location) {
    //     const searchParams = new URLSearchParams(location.search);
    //     return {
    //         query: searchParams.get('query') || '',
    //     };
    // }
    // const params = getParams('www.first-contrib.fr?query=react');

    let query = '';    
    if(typeof URLSearchParams == 'function'){
        const searchParams = new URLSearchParams(window.location.search);
        query = searchParams.get('q') || '';
    }
    
    const allsearchables = useStaticQuery(graphql`
        query searchable {
            allSitePage {
                nodes {
                    id
                    path
                    context {
                        tags {
                            name
                        }
                        categories {
                            name
                        }
                        title
                        content
                        excerpt

                        thisEdge {
                            categories {
                                name
                            }
                            title
                            content
                            excerpt                                
                        }
                    }
                }
            }
        }
    `).allSitePage.nodes;


    // Add in the team page entries...
    let teampages = pageContext.teamposts;
    let searchables = [];
    for(let i=0; i < allsearchables.length; i++) {
        searchables.push({ ...allsearchables[i]});  // This is so we can alter them without altering the allsearchable const's object
        if (searchables[i].path === '/about/team/') {
            searchables[i].context = allsearchables[i].context.thisEdge; // Team home page is slightly different
            for(let t=0; t < teampages.length; t++) {
                searchables[i].context.content += teampages[t].node.title + "\n";
                searchables[i].context.content += teampages[t].node.content + "\n";
            }
        }
    }
   
    // https://fusejs.io/ for the filtering
    let pages = []
    if (query !== false && query !== "") {
        var fuse = new Fuse(searchables, {
            shouldSort: true,
            threshold: 0.1,
            location: 0,
            distance: 10000000,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
              "context.title",
              "context.content",
              "context.tags.name",
              "context.categories.name"
            ]
        }); 

        pages = fuse.search(query);
        pages = pages.map((a)=><PageSummary page={a} key={a.id} />);
    }

    return(
        <Wrapper pageCurrent={ `team-home ${pageContext.title.toLowerCase()}` }>
            <SEO title={pageContext.title} />
        
            <div className="blockwrap menu-spacer">
                
                <div className="container base-styles">
                
                    <div className="content">
                        <div className="inner-heading-wrap">
                            <h1 className="animate-underline">{pageContext.title} for '{query}'</h1>
                        </div>
                        <div className="e" dangerouslySetInnerHTML={{__html: pageContext.content}} />

                    </div>
                    
                    
                </div>

               
            </div>
            <Iff condition={query !== false}>
                <div className="blockwrap">
                    <div className="container base-styles">
                        <div className="content">
                            <h2>Results for "{query}"</h2>
                            <SoftPagination size={10} dataset={query} none={<p>No matching entries found.</p>}>
                                {pages}
                            </SoftPagination>
                        </div>
                    </div>
                </div>
            </Iff>
            
        </Wrapper>

    )
};