import React from 'react';
import { Link } from 'gatsby';
import {htmlDecode} from '../../helpers.js';
import './style.scss';

export default ({page}) => {

    return <div className="page-summary">
        <Link to={page.path} className="PageSummary">
            <h3>{htmlDecode(page.context.title)}</h3>
        </Link>
        <div dangerouslySetInnerHTML={{__html: page.context.excerpt}}></div>
        <Link to={page.path} className="btn-basic">Read more <i className="fa fa-long-arrow-right" /></Link>        
    </div>
}