import React, {useState, useEffect} from 'react';
import './style.scss';
import Iff from '../Iff';


export default (props) => {
    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = props.children.length === 0 ? 0 : Math.ceil(props.children.length / props.size);

    let pageButtons = [];
    for(let i=0; i < totalPages; i++) {
        pageButtons.push( 
            <span className="pagination-numbers" key={i}>
                <button aria-current="page" className={"pagination-numbers" + (i === currentPage ? " active" : "")} onClick={e=>setCurrentPage(i)}>{i+1}</button>
            </span>
        );
    }

    // All this to reset the page on 
    const [dataset, setDataset] = useState(props.dataset);
    useEffect(
        () => {
            if (dataset !== props.dataset) {
                setDataset(props.dataset);
                setCurrentPage(0);
            }
        }
    );

    let children = props.children.filter((e,i) => {
        return i >= currentPage*props.size && i < (currentPage + 1) * props.size;
    })

    if (children.length === 0)
        children = props.none;

    return <div className="SoftPagination">
        {children}

        <div className="pagination">
            <Iff condition={currentPage > 0}>
                <button rel="prev" onClick={e=>setCurrentPage(currentPage - 1)}><span className="prev-arrow"><i className="fa fa-long-arrow-left"></i></span></button>
            </Iff>
            {pageButtons}
            <Iff condition={currentPage < totalPages - 1}>
                <button rel="next" onClick={e=>setCurrentPage(currentPage + 1)}><span className="next-arrow"><i className="fa fa-long-arrow-right"></i></span></button>
            </Iff>
        </div>
    </div>;
}